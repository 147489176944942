// routerPC.js
import {
    getToken
  } from '../utils/auth';
export const routerP = [{
        name: getToken() ? 'overview' : 'login',
        path: '/',
        redirect: getToken() ? '/overview' : '/login' /* hpp将index改称为了login */
    },
    {
        path: "/login",
        // component: resolve => require(['../components/page/login/login.vue'], resolve)
        component: () => import('@/views/login/login.vue')
    },
    {
        path: "/auth",
        component: () => import('@/views/auth')
    },
    {
        path: "/overview",
        component: () => import('@/views/overview')
    },
    {
        name: 'imgMark',
        path: "/imgMark",
        component: () => import('@/views/imgMark')
    },
    // +项目名
    {
        name: 'project',
        path: "/project",
        component: () => import('@/views/project')
    },
    // 任务管理 taskmanagement
    {
        name: 'task',
        path: "/task",
        component: () => import('@/views/task')
    },
    {
        name: 'taskview',
        path: "/taskview/:id",
        component: () => import('@/views/taskview'),
        meta: {
            title: '任务详情',
            keepAlive: true
        }
    },
    {
        name: 'moduleview',
        path: "/moduleview",
        component: () => import('@/views/moduleview'),
    },
    {
        name: 'detail',
        path: "/moduleview/detail",
        component: () => import('@/views/moduleview/detail.vue')
    },
    {
        name: 'projectlist',
        path: "/projectlist",
        component: () => import('@/views/project/projectList.vue')
    },
    {
        path: "/demo",
        component: () => import('@/components/demo')
    },
    {
        name: 'userhome',
        path: "/userhome",
        component: () => import('@/views/userHome')
    },
    {
        name: 'doc',
        path: "/doc",
        component: () => import('@/views/doc')
    },
    {
        name: 'videoMark',
        path: "/videoMark",
        component: () => import('@/views/videoMark')
    },
    {
        name: 'preview',
        path: "/preview",
        component: () => import('@/views/preview')
    },
    {
        name: 'roleRight',
        path: "/roleRight",
        component: () => import('@/views/roleRight')
    },
    {
        name: 'gwAdmin',
        path: "/gwAdmin",
        component: () => import('@/views/gwAdmin')
    },
    {
        name: 'news',
        path: "/gwAdmin/news/:id",
        component: () => import('@/views/gwAdmin/news.vue')
    },
]