export default {
    header: {
        project: "Project",
        module: "Model",
        en: '英文',
        zh: 'Chinese',
        changeLang: '切换语言',
        overview: 'Overview',
        task: 'Task',
        guide: 'Usage Guide',
        project1: 'Current Proj',
        task1: 'Current Task',
        new: "Beginner's Guide",
        noTask: 'No task selected',
        noProj: 'No proj selected',
        userHome: 'User home',
        loginOut: 'Login out',
        cantTO: 'There is currently no task selected, unable to jump',
    },
    overview: {
        projieMsg: 'Project Information',
        Resources: 'Project Resources',
        ProjectQuantity: 'Project',
        TaskQuantity: 'Task',
        inPro: 'Create projs',
        inTask: 'Participated tasks',
        picture: 'Resource Usage',
        allImg: "Imgs",
        allResources: 'Resources',
        upImgC: 'UpImgs',
        remainingResources: "RemainingResources",
        Spaceoccupancy: 'Space Occupancy',
        lastPro: 'Recent Project',
        newPro: 'new Project',
        proName: 'Project Name',
        createTime: 'Create Time',
        lastTime: 'Last-modified',
        Defect: 'Detection',
        continue: 'Continue',
        lastStudy: 'Recent Train',
        taskName: 'Task name',
        status: 'Status',
        overTime: 'TIME',
        leftTime: 'Remainder',
        taskType: 'Task type',
        userName: 'User name',
        cheack: 'Check'
    },
    project: {
        inupt: 'Enter the project name',
        proname: 'ProjectName',
        tasknum: 'Tasks',
        collaborate: 'Collaborate',
        ctime: 'Creat time',
        newpro: 'New Project',
        projectNotes: 'Project notes',
        proImg: 'Project LOGO',
        TimeD: 'Descending by time',
        TimeA: 'Ascending by time',
        NameD: 'Descending by name',
        NameA: 'Ascending by name',
        enterProject: 'Please enter the project name',
        user: 'collaborate with',
        create: 'Created by me',
    },
    upload: {
        mark: 'Drag the image here, or',
        or: 'click to select it'
    },
    button: {
        cancel: 'cancel',
        save: 'save',
        sure: 'determine',
        del: 'delete',
        add: 'add',
        edit: 'edit',
        up: 'upload',
        pre: 'preview',
        down: 'download',
        back: 'back',
        up1: 'Upload directly',
        upEdit: 'Capture and upload images'
    },
    task: {
        user: 'Founder',
        proid: 'ID',
        createTime: 'CreateTime',
        notes: 'Notes',
        userList: 'Collaboration List',
        username: 'Username',
        time: 'Time',
        edit: 'Delete',
        add: 'Add Collaborate',
        delete: 'Del Project',
        search: 'Enter the task name',
        new: 'New Task',
        taskName: 'Taskname',
        imgNum: 'ImgNum',
        unMarks: 'Unmarked',
        newTak: 'New Task',
        moduleNum: 'Models',
        ai: 'AI framework',
        imgType: 'Detection type',
        logo: 'Task LOGO',
        editPro: 'Edit',
        role: 'Roles',
        auth: 'Enable review',
        choose: 'Please select',
        dingwei: 'Target Location',
        dingwei1: 'Regional level, accurately identify targets',
        dingwei11: 'Multi-target',
        dingwei12: 'Multiple-defects',
        fenge: 'Image Segmentation',
        fenge1: 'Pixel level, suitable for finding product details',
        fenge11: 'Minor defects',
        fenge12: 'Complex scenes',
        fenlei: 'Image Classification',
        fenlei1: 'Image level, determine image category',
        fenlei11: 'Classification and grading',
        fenlei12: 'Classification of defects',
        changjing: 'Application scenarios',
        enterTask: 'Please enter the task name',
        selectType: 'Please select the type of image detection',
        enterUser: 'Please enter the user login ID',
        giveUser: 'Assigning roles',
        xzxq: 'Collaboration Details',
        nowRole: 'Current Role',
        noRole: 'NoRole',
        projMove: 'Project handover',
        suerMp: 'Are you sure to hand over this project?',
        img30: 'Thirty day annotation quantity',
        markNum: 'Number of annotations',
        noName: 'The project name cannot be empty',
        noName1: 'The task name cannot be empty',
        user1: 'user',
        noHas: 'absent',
        sureAdd: 'Confirm to add ',
        ma: ' Collaboration?',
        sureDel: 'Confirm to delete ',
        zzbz: 'Marking',
        shz: 'In review',
        dcb: 'Re mark',
        shtg: 'Approved',
        moveProj: "Are you sure to hand over this project?",
        wujiandu: 'Unsupervised Learning',
        wjdText: 'Just need the positive sample image',
        wjd11: 'Obvious defects',
        wjd12: 'Few defective samples',
    },
    role: {
        addRole: 'Add Role',
        roleID: 'Role ID',
        roleName: 'Role Name',
        roleRight: 'Rights',
        notes: 'Notes',
        delete: 'Delete',
        edit: 'Edit',
        editTime: 'Edit Time',
        suerDelete: 'Confirm to delete this role？',
        right: 'Rights',
        editRole: 'Edit Role',
        enterRole: 'Please enter the role name',
        one: 'Please select at least one permission'
    },
    img: {
        tag1: 'Tags',
        tagNum: 'Labels',
        state: 'State',
        use: 'Enable',
        nouse: 'Disable',
        addTag: 'Add labels',
        begin: 'Training',
        delete: 'Delete task',
        clone: 'Replication Task',
        img: 'IMG',
        module: 'MODEL',
        all: 'All',
        marked: 'Marked',
        unmark: 'Unmark',
        tagS: 'Tag filtering',
        allimg: 'All images',
        imgnum: 'Images',
        sort: 'Sort',
        timeD: 'Descending by time',
        timeU: 'Ascending by time',
        nameD: 'Descending by name',
        nameU: 'Ascending by name',
        look: 'View Favorite Images',
        allin: 'Select All',
        deleteS: 'Delete Selected',
        move: 'Move to one class',
        copyTo: 'Copy to other task',
        copyImg: 'Please choose which task to move to',
        sureCopyImg: 'Sure to copy the selected images to this task：',
        onlyImg: 'The selected task does not match the current task type. Only images can be copied, and annotation information cannot be copied. Are you sure to continue?',
        notcopyImg: 'Task type mismatch',
        open: 'Image manage',
        close: 'Close manage',
        upImg: 'Upload Images',
        upMarkImg: 'Upload marked images',
        markNum: 'Labels',
        editTask: 'Edit Task',
        addClass: 'Add child nodes under this node',
        editClass: 'Edit this node',
        dClass: 'Delete this node',
        fileName: 'fileName: ',
        uptime: 'uploadTime: ',
        imgI: 'imgSize: ',
        last: 'lastMarkTime: ',
        nomark: 'unMark',
        imgSize: 'fileSize：',
        reject: 'rejectReason：',
        eTag: 'Edit Tag',
        tagn: 'Tag name',
        tagnc: 'Tag name',
        color: 'Color',
        editC: 'Edit Class',
        addC: 'Add Class',
        classN: 'Class name',
        classI: 'Please enter the class name',
        upM1: 'Drag the compressed file here for uploading or ',
        upts: 'Click to select ',
        upM2: 'A compressed file',
        onlyZip: 'Only zip format files can be selected',
        ssC: 'Select the category for training',
        ssT: 'Select the label for training',
        selectPT: "Choose platform",
        gkj: 'IPC',
        hz: 'AI Controller',
        other: 'Professional Options',
        size: 'Size of target',
        s1: 'Convention',
        s2: 'General',
        s3: 'Delicate',
        many: 'iterations',
        cloneTo: 'Copy task to：',
        moveC: 'Choose a category',
        proL: 'Please select which project to copy this task to',
        deleteTask: 'This operation will delete the task. Do you want to continue?',
        deleteInfo: 'Task information to be deleted:',
        tagN: 'Tags',
        classN1: 'Categories',
        modelN: 'Models',
        sureD: 'Are you sure to continue deleting?',
        hasM: 'There are untrained models for this task',
        isMove: 'Do you want to move the selected image into this category?',
        isUpload: 'Do you want to upload the image to:',
        isUpload1: 'Do you want to upload compressed images to:',
        editRole: 'No permission to modify tasks',
        editTRole: 'No permission to modify tags',
        addTRole: 'No permission to add tags',
        trainRole: 'No training permission',
        deleteRole: 'No permission to delete tasks',
        cloneRole: 'No permission to copy tasks',
        over: 'Application review',
        authing: 'In review',
        toauth: 'To review',
        reImg: 'Reject images',
        pass: 'Approved',
        upRole: 'No permission to upload images',
        zipTitle: 'Select the category first before uploading the compressed file',
        deImgRole: 'No permission to delete images',
        word: 'Standard documents',
        clss: 'Click on any image box to select an area',
        clsss: 'Prohibited marking area',
        selectClsTag: 'Please select a category label',
        onlyWord: 'Only files in doc/docx/pdf format can be uploaded',
        downRole: 'No permission to download documents',
        preRole: 'No permission to view documents',
        delWordRole: 'No permission to delete documents',
        noword: 'No upload specification document available',
        wordName: 'Document name',
        upWtime: 'Uploaded',
        upUser: 'Upload user',
        classAll: 'Label all images under this category as',
        enterET: 'Please enter the English name of the label',
        enterCN: 'Please enter the English name of the label',
        selectC: 'Please choose a color',
        moveTitle: "Please drag and drop the mouse or click on the selected image, and then click the 'Move to' button to classify it.",
        sureDeL: 'Are you sure you want to delete it?',
        sureAu: 'Confirm that the annotation is complete, apply for review?',
        hasUn: 'Are you sure you want to confirm the application review if there are still unmarked pictures?',
        noAu: 'No audit permission',
        noImg: 'No Img',
        noTag: 'No label selected',
        sureImg: 'Are you sure to delete the selected image?',
        zipsize: 'The file size cannot exceed 1G',
        noClass: 'Unclassified',
        fenlei: 'Classify',
        onlyOne: 'The current restriction is to select one file',
        onlyCn: 'Only English, Chinese, numbers or_',
        train_name: 'Please enter the model name, do not enter the default task name+training ID',
        onlySee: '无标注权限,只可查看',
        chooseUpType: 'Please select the type of image to upload',
        zyb: 'Positive',
        ycyb: 'Abnormal',  
        nowImg: 'Now img is:',
    },
    upImg: {
        title1: 'Drag the image file here, or',
        title2: 'Click to select image upload',
        only: 'Only JPG/PNG/JPEG/BMP files can be uploaded, and the number of files should not exceed 3000',
        file: 'Select folder to upload',
        fileC: 'Number of files:',
        fileN: 'File Name',
        res: 'Result',
        clear: "Purge Files",
        t1: 'Successful but already exists',
        t2: 'Successful',
        t3: 'Failed',
        t4: 'Unsupported image type',
        t5: 'Successful and converted to JPG',
        t6: 'Image quota has been used up',
        t7: 'The file name is too long',
        t8: 'This file is an empty file',
        uping: 'Uploading',
        out: "The number of files exceeds 3000",
        kong: 'This folder is an empty folder',
        noS: "Please select a file",
        noC: 'Please clear the file first',
        bzc: 'Unsupported image type',
        upS: 'Upload successful',
        upO: 'Upload completed',
        up: 'Upload',
        sizeError: 'The selected image size is different. Please clear the image and select again',
        kuang: 'Please drag the red border and select the area to upload',
        selectModel: 'Please select a model',
        model: 'Upload the cropped image of the model',
        only1: 'Only JPG/PNG/JPEG/BMP files can be uploaded, and the number of files should not exceed 2000',
    },
    mark: {
        down: 'Download the current image',
        move: 'Change the classification of this image',
        lookall: 'ALL',
        looksame: 'Only view collections',
        pre: 'Previous',
        next: 'Next',
        b1: 'Translation',
        b2: 'Polygon drawing',
        b3: 'Rectangular drawing',
        b4: 'Revoke',
        showAll: "ALL",
        showAu: 'View Unaudited',
        showRe: 'View Rejected',
        save: 'SAVE',
        sc: "Collect this image",
        addTag: 'Add Tag',
        autoing: "AI automatic annotation training is currently underway",
        auto: 'AI automatic annotation',
        word: 'Standardized documents',
        pass: 'Pass',
        reject: 'Reject',
        over: 'Audit completed',
        imgList: 'ImgList',
        zhang: 'fix',
        sx: "Screen",
        reRE: 'Reason for rejection:',
        markList: 'Label List',
        clear: 'Clear all',
        del: 'del',
        chooseClass: 'Select category',
        cacel: "Unclassify",
        style: 'Style settings',
        line: "Line width:",
        tc: 'Fill transparency:',
        kuang: 'Label box:',
        name: 'Label Name:',
        grid: 'Grid：',
        color: 'Color extraction:',
        selectT: 'Please select the annotation label',
        addTag1: 'Please add tags first',
        inputRe: 'Please enter the reason for rejection',
        auRes: 'Finding of audit',
        reImgC: 'Number of rejected',
        noImgC: 'Number of unconfirmed',
        passAll: 'Click through all unconfirmed images',
        preMark: 'Pre annotation training',
        surePre: 'Do you want to overwrite the current annotation and start pre annotation?',
        yes: 'Overwrite the current annotation',
        no: 'Do not overwrite the current annotation',
        success: 'AI automatic annotation completed!',
        error: 'AI automatic annotation failed!',
        sccg: "Collection successful",
        qxsc: 'Unsubscribed successfully',
        sureDelT: 'Are you sure to delete this tag?',
        sureMove: 'Confirm to move the image in',
        isLast: "It's already the last one",
        noMark: 'Not annotated or modified',
        onPre: 'The current task is undergoing AI automatic annotation',
        huanyuan: 'Restore size',
        sureDelAll: 'Are you sure to clear all labels from the current image?',
        export: 'Export annotated images'
    },
    model: {
        input: 'Please enter model id, model name, or task name to search',
        id: 'Model ID',
        projName: "projName",
        taskId: 'taskId',
        pt: 'Platform',
        show: "View",
        modelInfo: 'Model Info',
        modelTest: 'Model Test',
        study: 'Deep Learning Training Metrics',
        study1: 'Training Indicators',
        c1: 'Serious',
        c2: 'Medium',
        c3: 'Excellent',
        zql: 'Accuracy',
        jcl: 'Detection',
        pjjd: 'Average',
        leftTime: 'Remainder',
        studyStatus: 'Training status',
        studyJD: 'Training progress',
        xxxx: 'Detailed information',
        studyUser: 'Promoter',
        studyTime: 'Training time',
        ddcs: 'Iteration Count',
        studySize: 'Target Size',
        imgCount: 'Training Image Count',
        file: 'Model File',
        tagStudy: 'Tag Training Metrics',
        zb: "Metrics",
        noOver: 'Training Not Completed',
        noBegin: 'Training Not Started',
        wait: 'Waiting',
        stop: 'Training Stopped',
        abnormal: 'Training Abnormally Stopped',
        finished: 'Training Finished',
        training: 'Training In Progress',
        starting: 'Training Starting',
        canShow: '(View Tag Training Metrics After Completion)',
        sureDel: 'This action will permanently delete the training plan. Continue?',
        upImg: "Local Image",
        taskImg: 'Task Images',
        bdImg: 'Local Images',
        zzjz: 'Loading',
        jjsb: 'Load Failed',
        zsyt: "Display Original Image",
        gbyt: "Close Original Image",
        testC: 'Tests Remaining:',
        begin: 'Start Testing',
        noTZ: 'No Features Detected in Image',
        stop1: 'Stop',
        zhibiao: 'Index(%)',
        modelIndex: 'Model training metrics',
        chooseImg: 'Please select an image',
        max50: 'The current restriction is to select 50 file',
        modelName: 'modelName',
        useTime:'Duration: ',
        fz: ' min',
        mayTime: 'Estimated time of ',
        xhed: 'Consume Resources'
    },
    login: {
        login: "Login",
        uLogin: 'Account Login',
        pLogin: 'Phone Verification',
        enAcc: 'Please enter account or phone number',
        enPwd: 'Please enter your password',
        drop: 'Drag slider to verify',
        noAcc: 'No account?',
        listRe: 'Sign Up Now',
        forget: "Forgot Password?",
        pDrop: "Please drag slider to verify",
        cantE: 'Account or password cannot be empty',
        enP: 'Please enter phone number',
        enC: 'Please enter verification code',
        send: 'Send Code',
        send1: 'Resend (',
        send2: 'Resend',
        acc: 'Account',
        ph: 'Phone',
        code: 'Code',
        pwd: 'Pwd',
        pwd1: 'PwdAgain',
        pwd2: 'Passwords do not match',
        pwd3: 'Please re-enter your password',
        acc1: "Please enter an account name in English",
        rePwd: 'Reset Password',
        regist: 'Regist',
        name: 'Full Name',
        company: 'Company',
        enCompany: 'Please enter your company name',
        email: 'Email',
        enEmail: 'Please enter a valid email address',
        hasA: 'Already have an account?',
        listL: 'Log In Now',
        Aph: "Please enter a valid phone number",
        min6: 'Must be at least 6 characters long',
        Ac: 'Please enter a valid verification code',
        pass: 'Verification Passed',
        ssr: 'SENSOR ',
        ai: ' AI Cloud Service Platform',
        p1: 'Easily control AI vision without programming experience!',
        p2: 'Start ——',
        p3: 'using it easily.'
    },
    userhome: {
        userInfo: 'User Info',
        editInfo: 'Edit Info',
        editPh: 'Edit Phone',
        newPh: "Please enter new phone",
        old: 'The phone number is consistent with the original phone number'
    },
    guide: {
        pre: "Previous Step",
        next: "Next Step",
        finish: "Finish",
        quit: "Quit",
        b1: 'Confirm to start the beginner guide?',
        b2: 'Confirm to start the beginner guide for the task details page?',
        b3: 'Confirm to start the beginner guide for the image labeling page?',
        proj1: 'First, we need to click here to enter the project page.',
        proj2: 'Click the "New Project" button to create a project, fill in the relevant information, and click confirm to complete the creation.',
        proj3: 'Find the newly created project here and click to enter it. After entering a project, you can start the next step of the guide.',
        task1: 'After entering the project, you can create a new task on this page.',
        task2: 'Click here to create a new task, fill in the relevant information, and click confirm to complete the creation.',
        task3: 'Find the newly created task here, click to enter the task, and then upload images to start labeling!',
        img1: 'On this page, we can upload images, classify images, filter and view images, add labels, add image categories, view trained models, and upload compressed packages of already labeled project images... Let’s introduce these one by one!',
        img2: 'After entering this page, we usually first need to create some categories, then upload different images into different categories for easy filtering, viewing, and labeling.',
        img3: 'Create a category here by hovering over the three dots behind a category and clicking the plus sign. You can create a subcategory under this category. After creating the category, select it and click to upload images to place them in this category.',
        img4: 'Upload images here. Before uploading, make sure to select the category to which the images should be uploaded; otherwise, they will default to the uncategorized section.',
        img5: 'Uploaded images will be displayed here. Click an image to enter the labeling page, but before labeling, we need to create some labels.',
        img6: 'Create labels here by clicking the plus sign, filling in the label information, and creating a new label.',
        img7: 'After creating the labels, click on an image here to enter the labeling page and start labeling.',
        img8: 'Once the images are labeled, click this button, select the images and labels to train, and start training the model.',
        img9: 'After starting the training, the system will generate a training plan. Click the "Model" button to switch to the training plan list page, where you can view detailed training information.',
        mark1: 'On this page, we can label or classify images. First, let’s introduce the functions of each area.',
        mark2: 'This is the function list area, where all labeling-related functions are located.',
        mark3: 'This is the image list area, displaying the images within the task. You can filter and display images. Click to select an image to switch the image in the labeling area to this image. Images with blue borders are already labeled, red borders indicate the currently selected image, and gray borders indicate unlabeled images.',
        mark4: 'This is the image labeling area. Click the labeling box button above and move the mouse here to label the image.',
        mark5: 'This displays the current image’s labeling information list, where you can modify, replace, or delete labeling information.',
        mark6: 'Here, you can set the style of the labeling box, such as changing the thickness of the box or adjusting its transparency.',
        mark7: 'After introducing the functions of each area on the page, let’s provide a detailed introduction to the function list area above.',
        mark8: 'This displays the name and dimensions (width and height) of the current image.',
        mark9: 'Click this button to download the current image.',
        mark10: 'Click this button to move the current image to another category.',
        mark11: 'Click this button to filter out all favorited images.',
        mark12: 'Click this button to switch to the previous image. If the current image has been labeled or modified, the system will automatically save the labeling information.',
        mark13: 'Click this button to switch to the move state. Enter the labeling area with the mouse, hold down and move the mouse to move the image.',
        mark14: 'Click this button to switch to the labeling state. Enter the labeling area with the mouse, hold down and move the mouse to label the image.',
        mark15: 'Click this button to undo the last labeling box.',
        mark16: 'Click this button to zoom in on the image in the labeling area.',
        mark17: 'Click this button to zoom out the image in the labeling area.',
        mark18: 'Click this button to switch to the next image. If the current image has been labeled or modified, the system will automatically save the labeling information.',
        mark19: 'Click this button to save the current image’s labeling information.',
        mark20: 'Click this button to favorite the current image.',
        mark21: 'Click this button to add new labels to the current task.',
        mark22: 'Click this button to upload, view, or delete standard documents.',
    },
    main: {
        oneTo20: 'Length ranging from 1 to 20 characters',
        oneTo200: 'Length ranging from 1 to 200 characters',
        title: 'Prompt',
        caozuo: 'Operate',
        guifan: 'Please fill in the form in a standardized manner',
        warn: 'Warning',
        dan: 'Do not input single or double quotes',
        xiahua: 'Please enter a valid format (only English letters, digits, and underscores)!',
        success: 'Success',
        fail: 'Fail',
        wu: 'No data',
        wait: 'Waiting',
        nodata: 'Nil',
    }
};